import Vue from 'vue'
import App from './App.vue'
import HighchartsVue from 'highcharts-vue'
import VueGtag from "vue-gtag";
import VueHead from 'vue-head'
 
Vue.config.productionTip = false
Vue.use(HighchartsVue)

Vue.use(VueHead)

Vue.use(VueGtag, {
  config: { id: "G-ZLQBNMQS4Z" }
});

new Vue({
  render: h => h(App),
}).$mount('#app')
