<template>
  <div id="app">
    <div class="header">
      <img class="logo" src="@/assets/logo.svg" /><span class="mini"
        >Veriler 15dk gecikmelidir.</span
      >
    </div>
    <div class="hc">
      <highcharts :options="chartOptions"></highcharts>
    </div>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import treeMapInit from "highcharts/modules/treemap";
import axios from "axios";

treeMapInit(Highcharts);

export default {
  name: "App",
  components: {
    highcharts: Chart,
  },
  head: {
    title: {
      inner: "Grafikler",
    }
  },
  created() {
    this.interval = setInterval(() => this.getBist(), 2000);
    this.initData();
  },
  methods: {
    initData() {
      axios.get("/api/init").then((response) => {
        this.seriesData = response.data;
      });
    },
    getBist() {
      axios.get("/api/current").then((response) => {
        this.updateChartColors(response.data);
      });
    },
    updateChartColors(currentData) {
      Number.prototype.map = function (in_min, in_max, out_min, out_max) {
        return (
          ((this - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
        );
      };

      currentData.forEach((e) => {
        this.dataMap[e.sym] = e;
      });

      this.chartOptions.series[0].data.forEach((e) => {
        if (e.sym) {
          var magnitude =
            ((this.dataMap[e.sym].percent_diff - -5) * (100 - -100)) / (5 - -5) - 100;
          e.percentage = this.dataMap[e.sym].percent_diff;
          if (magnitude > 0) {
            e.color = "hsl(145, " + (0 + Math.abs(magnitude)) + "%, 40%)";
          } else if (magnitude < 0) {
            e.color = "hsl(355, " + (0 + Math.abs(magnitude)) + "%, 40%)";
          } else {
            e.color = "hsl(355, 0%, 40%)";
          }
          e.name = e.name + " ";
        }
      });
    },
  },
  data() {
    return {
      dataMap: new Map(),
      seriesData: null,
    };
  },
  computed: {
    chartOptions() {
      var ctx = this;
      return {
        title: {
          text: null,
        },
        credits: {
          enabled: false,
        },
        chart: {
          backgroundColor: "#222",
          style: {
            fontFamily: "Roboto",
          },
          events: {
            load: function () {
              ctx.getBist();
            },
          },
        },
        tooltip: {
          borderWidth: 0,
          borderRadius: 0,
          backgroundColor: "rgba(255,255,255,0)",
          shadow: false,
          formatter: function () {
            if (!this.point.options.sym) {
              return null;
            }
            var details = ctx.dataMap[this.point.options.sym];
            return (
              "<div class='tooltip'><b style='color:" +
              this.point.color +
              "'>" +
              this.point.options.sym +
              ": " +
              details.last_value +
              " (" +
              details.percent_diff +
              "%)" +
              "</b><br><p>" +
              this.point.options.def +
              "</p>" +
              "<br><p>Alış: " +
              details.buy +
              "</p>" +
              "<p>Satış: " +
              details.sell +
              "</p><hr class='light'>" +
              "<p>Son 30 Gün:</p><img src='/bist_thumb/" +
              details.full_sym +
              ".png'><hr class='light'>" +
              "<p>Diğer Değerler:</p><br><p>Hacim: " +
              this.point.options.volume +
              " TL</p>" +
              "<p>Piyasa Değeri: " +
              this.point.options.market_value +
              " TL</p>" +
              "<p>Sermaye: " +
              this.point.options.capital +
              " TL</p>" +
              "<hr class='light'><p>Veri Saati: " +
              details.last_data_time +
              "</p></div>"
            );
          },
          useHTML: true,
        },
        series: [
          {
            type: "treemap",
            alternateStartingDirection: true,
            levels: [
              {
                level: 1,
                layoutAlgorithm: "squarified",
                dataLabels: {
                  formatter: function () {
                    return (
                      "<span style='position:relative; background-color:#222; color: #fff; padding:5px; left:-5px;'>" +
                      this.key +
                      "</span>"
                    );
                  },
                  enabled: true,
                  useHTML: true,
                  align: "left",
                  verticalAlign: "top",
                },
                borderColor: "#222",
                borderWidth: 6,
              },
              {
                level: 2,
                dataLabels: {
                  formatter: function () {
                    if (this.y < 5000000000) {
                      return null;
                    }
                    var vw = Math.min(this.y/15000000000, 1.5);
                    console.log(this)
                    return (
                      "<p style='margin:0; font-size:" + vw + "vw; text-shadow: rgba(0, 0, 0, 0.120) 1px 0px 0px, rgba(0, 0, 0, 0.120) 0.540302px 0.841471px 0px, rgba(0, 0, 0, 0.120) -0.416147px 0.909297px 0px, rgba(0, 0, 0, 0.120) -0.989993px 0.14112px 0px, rgba(0, 0, 0, 0.120) -0.653644px -0.756803px 0px, rgba(0, 0, 0, 0.120) 0.283662px -0.958924px 0px, rgba(0, 0, 0, 0.120) 0.96017px -0.279416px 0px;'>" +
                      this.key +
                      "<br>"+
                      this.percentage + "%" +
                      "</p>"
                    );
                    //return this.key;
                  },
                  enabled: true,
                  color: "#ffffff",
                  style: {
                    fontSize: "20px",
                    textOutline: null,
                    textAlign: "center",
                  },
                  useHTML: true,
                },
                layoutAlgorithm: "squarified",
                borderColor: "#222",
                borderWidth: 3,
              },
            ],
            data: ctx.seriesData,
          },
        ],
      };
    },
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');

html,
body,
#app {
  display: flex;
  flex-direction: column;
  margin: 0;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #222;
}

.logo {
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
}

.header {
  flex: 0.06;
}

.hc {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.hc div {
  min-height: 0;
  flex: 1;
}

.tooltip {
  padding: 10px;
  border-radius: 5px;
  color: #333;
  background-color: #ffffff;
}

.tooltip img {
  min-height: 45px;
}

.tooltip p {
  margin: 0;
}

.light {
  border-top: 1px dashed #aaa;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}

.mini {
  color: #aaa;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
}

input {
  color: transparent;
  text-shadow: 0 0 0 #2196f3;
}
</style>
